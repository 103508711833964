<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="gridmoc"
        title="관련 MOC 목록"
        tableId="gridmoc"
        :columns="gridmoc.columns"
        :data="gridmoc.data"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
              <c-btn v-if="editable" label="추가" icon="add" />
              <c-btn v-if="editable" label="삭제" icon="remove" />
              <c-btn v-if="editable" label="저장" icon="save" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'equipment-bom',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {}
      },
    },
  },
  data() {
    return {
      editable: true,
      gridmoc: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: 'MOC번호',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: 'MOC명',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '상태',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getList();
    },
    getList() {
      this.gridmoc.data = [
        {
          col1: 'MOC-00001',
          col2: '변경검토1',
          col3: '발의',
        },
      ]
    },
  }
};
</script>